<template>
  <div class="c-header" id="headerMenu">
    <div class="c-header__languages">
      <div class="g-wrapper">
        <a
          ref="este"
          id="es"
          v-on:click="changeLanguage('es')"
          class="c-header__languages__item c-header__languages__item__es g--font-size-4 g--font-size-4@sm g--font-size-4@md g--font-size-5@lg"
          >ES</a
        >
        <a
          id="eng"
          v-on:click="changeLanguage('eng')"
          class="c-header__languages__item c-header__languages__item__eng g--font-size-4 g--font-size-4@sm g--font-size-4@md g--font-size-5@lg"
          >ENG</a
        >
        <a
          id="fra"
          v-on:click="changeLanguage('fra')"
          class="c-header__languages__item c-header__languages__item__fra g--font-size-4 g--font-size-4@sm g--font-size-4@md g--font-size-5@lg"
          >FRA</a
        >
      </div>
    </div>
    <div class="c-header__menu">
      <div class="g-wrapper">
        <router-link to="/home" class="c-header__menu__logo">
          <img
            v-on:click="
              $root.$emit('moveTo', 0);
              closeHeader();
            "
            :src="requireAsset('logo_cercos_white.webp')"
            alt=""
            class="c-header__menu__logo"
        /></router-link>
        <div class="c-header__menu__items">
          <router-link
            class="c-header__menu__items__item c-header__menu__items__item__home g--font-size-6 g--font-size-6@sm g--font-size-6@md g--font-size-7@lg"
            to="/home"
            ><span
              v-on:click="
                $root.$emit('moveTo', 0);
                closeHeader();
              "
              >{{ $t("header.link_1") }}</span
            ></router-link
          >
          <router-link
            class="c-header__menu__items__item c-header__menu__items__item__contract g--font-size-6 g--font-size-6@sm g--font-size-6@md g--font-size-7@lg"
            to="/contract"
            ><span
              v-on:click="
                $root.$emit('moveTo', 0);
                closeHeader();
              "
              >{{ $t("header.link_2") }}</span
            ></router-link
          >
          <router-link
            class="c-header__menu__items__item c-header__menu__items__item__carpentry g--font-size-6 g--font-size-6@sm g--font-size-6@md g--font-size-7@lg"
            to="/carpentry"
            ><span
              v-on:click="
                $root.$emit('moveTo', 1);
                closeHeader();
              "
              >{{ $t("header.link_3") }}</span
            ></router-link
          >
          <router-link
            class="c-header__menu__items__item c-header__menu__items__item__contact g--font-size-6 g--font-size-6@sm g--font-size-6@md g--font-size-7@lg"
            to="/contact"
            ><span
              v-on:click="
                $root.$emit('moveTo', 0);
                closeHeader();
              "
              >{{ $t("header.link_4") }}</span
            ></router-link
          >
        </div>
      </div>
      <div class="c-header__menu__hamburguer" @click="setRespoHeader()">
        <div class="c-header__menu__hamburguer__bar1"></div>
        <div class="c-header__menu__hamburguer__bar2"></div>
        <div class="c-header__menu__hamburguer__bar3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import i18n from "@/i18n";
export default {
  name: "Header",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.stickyHeader();
    var storedCookie = document.cookie.split("=")[1];
    if (storedCookie != null) {
      this.showLangActive(storedCookie);
    } else {
      this.showLangActive("es");
    }
  },
  methods: {
    closeHeader() {
      var header = document.getElementById("headerMenu");
      if (header.classList.contains("respo")) {
        header.classList.remove("respo");
      }
    },
    setRespoHeader() {
      document.getElementById("headerMenu").classList.toggle("respo");
    },
    changeLanguage(language) {
      document.cookie = "language=" + language + ";path='/'";
      if (language === "es") {
        i18n.locale = "es";
        this.showLangActive("es");
      }
      if (language === "eng") {
        i18n.locale = "eng";
        this.showLangActive("eng");
      }
      if (language === "fra") {
        i18n.locale = "fra";
        this.showLangActive("fra");
      }
    },
    showLangActive(language) {
      var languagesMenu = document.getElementsByClassName(
        "c-header__languages"
      )[0].children[0].children;

      for (let languageLink of languagesMenu) {
        if (languageLink.classList.contains("active")) {
          languageLink.classList.remove("active");
        }
      }
      try {
        document.getElementById(language).classList.add("active");
        document.getElementById(language).click();
      } catch (error) {
        this.$refs.este.click();
      }
    },
    stickyHeader() {
      window.document.onscroll = () => {
        let header = document.getElementsByClassName("c-header")[0];
        if (this.windowWidth >= 700) {
          if (window.scrollY > header.offsetTop) {
            header.classList.add("c-header__sticky");
          } else {
            header.classList.remove("c-header__sticky");
          }
        }
      };
    },
  },
};
</script>

<style lang="scss">
.c-header {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  &__languages {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    z-index: 999;
    width: 100%;
    height: 32px;
    background: #1e1e1e;
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-decoration: none;

      //TRANISTION COLOR HOVER
      color: #c9c9c9;
      transition: 0.4s color ease;
      ////////////////////////

      padding: 10px;

      &__fra {
        margin-left: 12px;
      }
      &__eng {
        margin-left: 12px;
      }
    }
    &__item:hover {
      color: #ffffff;
      cursor: pointer;
    }
  }
  &__menu:before {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    content: " ";
    width: 100%;
    height: 155px;
    opacity: 0.6;
    background: #1e1e1e;

    //TRANSITION MARGIN-TOP ON STICKY
    margin-top: 0px;
    transition: 0.4s all ease;
    /////////////////////////////
  }
  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    width: 100%;

    //TRANSITION HEIGHT ON STICKY
    height: 155px;
    transition: 0.4s height ease;
    /////////////////////////////
    &__items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 10px;
      &__item {
        position: relative;
        text-decoration: none;
        color: white;
        display: inline-block;
        margin: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &__home {
          margin-right: 40px;
        }
        &__contract {
          margin-right: 40px;
        }
        &__carpentry {
          margin-right: 40px;
        }
      }
      &__item:after {
        display: block;
        content: "";
        border-bottom: solid 0.1px white;

        //TRANISTION TRANSFORMATION ON HOVER
        transform: scaleX(0);
        transition: 0.4s transform ease;
        ////////////////////////
      }
      &__item:hover:after {
        transform: scaleX(1);
      }
    }
    &__logo {
      width: 140px;
      @media (max-width: 1023px) {
        width: 120px;
      }
      @media (max-width: 760px) {
        width: 100px;
      }
      @media (max-width: 720px) {
        width: 90px;
      }
      @media (max-width: 700px) {
        width: 120px;
      }
      @media (max-width: 400px) {
        width: 100px;
      }
      margin-left: 10px;
      z-index: 999;
      //TRANSITION HEIGHT ON STICKY
      transition: 0.4s width ease;
      /////////////////////////////
    }
  }
  &__sticky {
    position: fixed;
    top: 0;
    .c-header {
      &__menu:before {
        margin-top: -65px;
      }
      &__menu {
        height: 90px;
        &__logo {
          width: 90px;
        }
      }
    }
  }

  //RESPONIVE HEADER
  @media (max-width: 700px) {
    &__menu {
      height: 155px;
      &:before {
        height: 155px;
      }
      &__items {
        &__item {
          pointer-events: none;
          position: absolute;
          transition: 0.4s all ease-in-out;
          opacity: 0;
          left: 0;
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 600px;
          -webkit-tap-highlight-color: transparent;
          &__home {
            margin-bottom: 10px;
            width: 40px;
            left: 10px;
            top: 100px;
            position: absolute;
          }
          &__contract {
            width: 250px;
            left: 10px;
            top: 130px;
            position: absolute;
          }
          &__carpentry {
            width: 70px;
            left: 10px;
            top: 160px;
            position: absolute;
          }
          &__contact {
            width: 40px;
            left: 10px;
            top: 190px;
            position: absolute;
          }
        }
        &__item:after {
          width: 0px;
        }
      }
      &__hamburguer {
        -webkit-tap-highlight-color: transparent;
        z-index: 999;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        &__bar1,
        &__bar2,
        &__bar3 {
          width: 35px;
          height: 5px;
          background-color: white;
          margin: 6px 0;
          transition: 0.4s;
        }
      }
    }

    &.respo {
      & .c-header__menu__items__item {
        pointer-events: visible;
      }
      .c-header__menu__hamburguer {
        &__bar1 {
          -webkit-transform: rotate(-45deg) translate(-9px, 6px);
          transform: rotate(-45deg) translate(-9px, 6px);
        }
        &__bar2 {
          opacity: 0;
        }
        &__bar3 {
          -webkit-transform: rotate(45deg) translate(-8px, -8px);
          transform: rotate(45deg) translate(-8px, -8px);
        }
      }
      .c-header__menu:before {
        height: 420px;
      }
      .c-header__menu__items {
        &__item {
          transition: 0.4s all ease-in-out;
          opacity: 1;
        }
      }
    }
  }
  .active {
    color: white;
    font-weight: bold;
  }
}
</style>
