<template>
  <div class="collection-detail-page">
    <div class="collection-detail-page-back">
      <i class="arrow left" @click="backToSpeFurniture()"></i>
    </div>
    <div class="main-section">
      <span
        class="collection-title g--font-size-7 g--font-size-8@sm g--font-size-9@md g--font-size-10@lg"
        >{{
          $t("furniture_page.collection") + " " + $t(collectionTitleShown())
        }}</span
      >
      <div class="underlined-separator"></div>
    </div>
    <div class="g-wrapper">
      <div class="collection-gallery">
        <div
          v-for="(image, i) in data[id.collectionId].images"
          :key="i"
          class="collection-gallery__container"
          @click="index = i"
        >
          <img
            class="carousel__loader"
            :src="requireAsset('loader.gif')"
            alt=""
          />
          <img
            @load="ImageLoaded($event)"
            :src="image"
            class="collection-gallery__container__image"
            alt=""
          />
        </div>
      </div>
    </div>
    <vue-gallery-slideshow
      :images="data[id.collectionId].images"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      id: this.$route.params,
      index: null,
      data: [
        {
          images: [
            this.requireAsset("furniture/classic/BEDROOM_11/1.webp"),
            this.requireAsset("furniture/classic/BEDROOM_11/2.webp"),
            this.requireAsset("furniture/classic/BEDROOM_11/3.webp"),
            this.requireAsset("furniture/classic/BEDROOM_11/4.webp"),
            this.requireAsset("furniture/classic/BEDROOM_11/5.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/classic/COLECCION_EVORA/1.webp"),
            this.requireAsset("furniture/classic/COLECCION_EVORA/2.webp"),
            this.requireAsset("furniture/classic/COLECCION_EVORA/3.webp"),
            this.requireAsset("furniture/classic/COLECCION_EVORA/4.webp"),
            this.requireAsset("furniture/classic/COLECCION_EVORA/5.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/classic/COLECCION_FABULA/1.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/2.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/3.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/4.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/5.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/6.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/7.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/8.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/9.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/10.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/11.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/12.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/13.webp"),
            this.requireAsset("furniture/classic/COLECCION_FABULA/14.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/classic/COLECCION_MILAN/1.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/3.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/4.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/5.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/6.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/7.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/8.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/9.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/10.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/11.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/12.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/13.webp"),
            this.requireAsset("furniture/classic/COLECCION_MILAN/14.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/classic/COLECCION_SAMARA/1.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/2.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/3.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/4.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/5.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/6.webp"),
            this.requireAsset("furniture/classic/COLECCION_SAMARA/7.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/1.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/2.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/3.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/4.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/5.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/6.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/7.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/8.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/9.webp"),
            this.requireAsset("furniture/classic/COLECCION_VALENCIA/10.webp"),
          ],
        },
        {
          images: [
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/1.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/2.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/3.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/4.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/5.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/6.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/7.webp"),
            this.requireAsset("furniture/contemporary/COLECCION_SFERA/8.webp"),
          ],
        },
        {
          images: [
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/1.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/2.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/3.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/4.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/5.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/6.webp"
            ),
            this.requireAsset(
              "furniture/contemporary/COLECCION_VIDA_CONTEMPORANEA/8.webp"
            ),
          ],
        },
      ],
    };
  },
  methods: {
    ImageLoaded(e) {
      if (e.target == null) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }

      setTimeout(() => {
        this.Loading = false;
        setTimeout(() => {
          element.style.pointerEvents = "visible";
          element.style.opacity = 1;
        }, 400);
      }, 1000);
    },
    backToSpeFurniture() {
      this.$router.push("/furniture");
      setTimeout(() => {
        this.$root.$emit("ref", this.id.collectionId);
      }, 500);
    },
    collectionTitleShown() {
      var title = "";
      var x = this.id.collectionId;
      var xStr = x.toString();
      switch (xStr) {
        case "0":
          title = "furniture_page.classic_section.bedroom_11";
          break;
        case "1":
          title = "furniture_page.classic_section.evora";
          break;
        case "2":
          title = "furniture_page.classic_section.fabula";
          break;
        case "3":
          title = "furniture_page.classic_section.milan";
          break;
        case "4":
          title = "furniture_page.classic_section.samara";
          break;
        case "5":
          title = "furniture_page.classic_section.valencia";
          break;
        case "6":
          title = "furniture_page.contemporary_section.sfera";
          break;
        case "7":
          title = "furniture_page.contemporary_section.life";
          break;
      }
      return title;
    },
  },
};
</script>

<style lang="scss">
.collection-detail-page {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .collection-detail-page-back {
    position: absolute;
    top: 130px;
    left: 100px;
    width: 50px;
    height: 50px;
    @media (max-width: 1300px) {
      left: 5%;
    }
    @media (min-width: 1650px) {
      left: 10%;
    }
    @media (min-width: 2300px) {
      left: 20%;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 10px;
    transition: 0.4s all ease-in-out;
    &:hover {
      border: solid #e9be35;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 10px;
    }
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .collection-title {
      font-weight: bold;
    }

    .collection-description {
      margin-top: 20px;
      text-align: center;
      font-weight: bold;
    }

    .underlined-separator {
      margin-top: 30px;
      width: 70%;
      border-bottom: 2px solid #e9be35;
    }
  }

  .collection-gallery {
    opacity: 1;
    --gap: 1em;
    --columns: 3;
    max-width: 100%;
    margin: 0 auto;
    columns: var(--columns);
    gap: var(--gap);
    & > * {
      break-inside: avoid;
      margin-bottom: var(--gap);
    }
    @supports (grid-template-rows: masonry) {
      display: grid;
      grid-template-columns: repeat(var(--columns), 1fr);
      grid-template-rows: masonry;
      grid-auto-flow: dense;
      & > * {
        margin-bottom: 0em;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      overflow: hidden;
      opacity: 1;
      transform: scale(1);
      transition: 0.35s ease-in-out all;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      &__image {
        z-index: 10000;
        pointer-events: none;
        opacity: 0;
        cursor: pointer;
        width: 100% !important;
        height: auto !important;
        object-fit: cover;
        transition: 0.35s ease-in-out all;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        overflow: hidden;
        &:hover {
          transform: scale(1.1);
          -webkit-filter: brightness(65%);
        }
      }
    }
  }
}
.carousel__loader {
  position: absolute;
}
</style>
