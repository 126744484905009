<template>
  <carousel
    class="carousel"
    :animation-speed="1000"
    :autoplay="true"
    :autoplay-timeout="4000"
    :border="0"
    :controls-visible="false"
    :disable3d="true"
    :display="1"
    :loop="true"
    :perPage="1"
    :paginationEnabled="false"
  >
    <slide
      class="carousel__slide"
      v-for="(Image, index) in data[position].images"
      :key="index"
    >
      <img class="carousel__slide__image" :src="requireAsset(Image)" alt="" />
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  props: {
    Data: Array,
  },
  data() {
    return {
      autoplayTimeout: 100,
      Pos: 2,
      FirstRun: false,
      position: 0,
      data: [
        {
          images: [
            "carousel/0.webp",
            "carousel/1.webp",
            "carousel/2.webp",
            "carousel/3.webp",
            "carousel/4.webp",
            "carousel/6.webp",
            "carousel/7.webp",
            "carousel/8.webp",
            "carousel/9.webp",
            "carousel/10.webp",
            "carousel/11.webp",
            "carousel/12.webp",
            "carousel/13.webp",
            "carousel/14.webp",
            "carousel/15.webp",
            "carousel/16.webp",
            "carousel/17.webp",
            "carousel/18.webp",
            "carousel/19.webp",
            "carousel/20.webp",
            "carousel/21.webp",
            "carousel/22.webp",
            "carousel/23.webp",
          ],
        },
        {
          images: [
            //CARPENTRY
            "carpentry/armarios/1.webp",
            "carpentry/doors/4.webp",
            "carpentry/walls/3.webp",
            "carpentry/armarios/3.webp",
            "carpentry/doors/5.webp",
            "carpentry/walls/4.webp",
            "carpentry/armarios/4.webp",
            "carpentry/doors/8.webp",
            "carpentry/walls/5.webp",
            "carpentry/armarios/5.webp",
            "carpentry/walls/6.webp",
            "carpentry/armarios/6.webp",
          ],
        },
        {
          images: [
            //FURNITURE
            "furniture/classic/COLECCION_MILAN/7.webp",
            "furniture/classic/COLECCION_MILAN/8.webp",
            "furniture/classic/COLECCION_MILAN/12.webp",
            "furniture/classic/COLECCION_SAMARA/2.webp",
            "furniture/classic/COLECCION_SAMARA/3.webp",
            "furniture/classic/COLECCION_SAMARA/4.webp",
            "furniture/classic/COLECCION_VALENCIA/4.webp",
            "furniture/classic/COLECCION_VALENCIA/1.webp",
            "furniture/classic/COLECCION_FABULA/1.webp",
            "furniture/contemporary/COLECCION_SFERA/2.webp",
            "furniture/contemporary/COLECCION_SFERA/7.webp",
            "furniture/contemporary/COLECCION_SFERA/8.webp",
          ],
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
  },
  mounted() {
    this.value = 0;
    var actualUrl = window.location.href;
    if (actualUrl.includes("carpentry")) {
      this.position = 1;
    } else if (actualUrl.includes("furniture")) {
      this.position = 2;
    } else {
      this.position = 0;
    }

    this.$root.$on("moveTo", (pos) => {
      this.animateCarousel();
      setTimeout(() => {
        this.position = pos;
        this.value = 0;
      }, 550);
    });
  },
  methods: {
    animateCarousel() {
      var carousel = document.getElementById("carousel");
      carousel.style.opacity = 0;
      setTimeout(() => {
        carousel.style.opacity = 1;
      }, 1000);
    },
    ContentLoad(e) {
      if (e.target == null || e.target == undefined) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }
      setTimeout(() => {
        document.getElementsByClassName("carousel")[0].style.opacity = 1;
        setTimeout(() => {
          element.classList.remove("blurred");
        }, 350);
      }, 350);
    },
    pageChange() {
      if (this.FirstRun == false) {
        this.autoplayTimeout = 6000;
        this.Pos = 0;
        this.FirstRun = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  width: 100dvw !important;
  height: 100dvh !important;
  pointer-events: none;
  &__slide {
    width: 100dvw !important;
    height: 100dvh !important;
    &__image {
      width: 100dvw !important;
      height: 100dvh !important;
      object-fit: cover;
    }
  }
}
</style>
