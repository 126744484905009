<template>
  <div class="g-wrapper">
    <lazy-component wrapper-tag="section" class="c-contract">
      <div
        class="c-contract__title g--font-size-8 g--font-size-8@sm g--font-size-9@md g--font-size-10@lg"
      >
        {{ $t("header.link_2") }}
      </div>
      <div
        class="c-contract__underline g--width-5 g--width-6@sm g--width-6@md g--width-7@lg g--borderBottom-1 g--borderBottom-2@sm g--borderBottom-3@md g--borderBottom-4@lg"
      ></div>

      <div class="c-contract__carousel">
        <div class="c-contract__carousel__data">
          <div id="data" class="c-contract__carousel__data__container">
            <div class="c-contract__carousel__data__container__title">
              <div style="width: 70%; margin-bottom: 10px">
                <h2
                  class="c-contract__carousel__data__container__title__name g--font-size-6 g--font-size-7@sm g--font-size-8@md g--font-size-9@lg"
                >
                  {{ $t(data[position].title) }}
                </h2>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  align-content: center;
                "
              >
                <h2
                  class="c-contract__carousel__data__container__title__stars g--font-size-6 g--font-size-7@sm g--font-size-8@md g--font-size-9@lg"
                >
                  {{ $t(data[position].stars) }}
                </h2>
              </div>
            </div>
            <h2
              class="c-contract__carousel__data__container__location g--font-size-4 g--font-size-4@sm g--font-size-5@md g--font-size-7@lg"
            >
              {{ $t(data[position].location) }}
            </h2>
            <h2
              class="c-contract__carousel__data__container__sector g--font-size-3 g--font-size-3@sm g--font-size-4@md g--font-size-5@lg"
            >
              {{ $t(data[position].sector) }}
            </h2>
            <div
              class="c-contract__carousel__data__container__link__space"
            ></div>
            <router-link
              class="c-contract__carousel__data__container__link hide"
              to="/furniture"
            >
              <button>
                {{ $t("home_page.contract_component.visitContract") }}
              </button>
            </router-link>
          </div>
          <div class="c-contract__carousel__data__container__buttons">
            <p><i @click="prevHotel()" class="arrow arrow__left"></i></p>
            <p><i @click="nextHotel()" class="arrow arrow__right"></i></p>
          </div>
        </div>
        <div class="c-contract__carousel__container">
          <div class="c-contract__carousel__container__loader"></div>
          <img
            @load="ContentLoaded($event)"
            v-on:click="sendToContract(position)"
            class="c-contract__carousel__container__background"
            id="backgroundImgContract"
            :src="requireAsset(backgroundImage[position])"
          />
        </div>
      </div>
    </lazy-component>
  </div>
</template>

<script>
import LazyComponent from "v-lazy-component";

export default {
  components: {
    LazyComponent,
  },
  data() {
    return {
      backgroundImage: "",
      position: 0,
      images: [
        "contract/HOTEL_H10_PORTO_PONIENTE/1.webp",
        "contract/HOTEL_COSTA_CALERO/1.webp",
        "contract/HOTEL_H10_BLUE_MAR_BOUTIQUE/1.webp",
        "contract/HOTEL_H10_CASA_DE_LA_PLATA/1.webp",
        "contract/HOTEL_H10_CASA_DEL_MAR/1.webp",
        "contract/HOTEL_H10_CUBIK/1.webp",
        "contract/HOTEL_H10_MARINA/1.webp",
        "contract/HOTEL_H10_PUNTA_NEGRA/1.webp",
        "contract/HOTEL_H10_THE_ONE/1.webp",
        "contract/APARTAHOTEL_SPA_ESQUIROL/1.webp",
        "contract/BATTOYOR_HOLDING_TOWER_EN_AL_KNOBAR/1.webp",
        "contract/CASINO_GRAN_VIA_MADRID/1.webp",
        "contract/HOTEL_AUBERGE_DU_MOULIN/1.webp",
        "contract/HOTEL_MARRIOTT_ASTANA/1.webp",
        "contract/HOTEL_PRINCESA_PARC/1.webp",
        "contract/HOTEL_PULLMAN/1.webp",
        "contract/VILLA_RESIDENCIA/1.webp",
        "contract/HOTEL_H10_INTERCONTINENTAL/DSCF0038.webp",
      ],
      data: [
        {
          title: "contract_page.projects.contract_1.title",
          stars: "contract_page.projects.contract_1.stars",
          location: "contract_page.projects.contract_1.location",
          description: "contract_page.projects.contract_1.description",
          sector: "contract_page.projects.contract_1.sector",
        },
        {
          title: "contract_page.projects.contract_2.title",
          stars: "contract_page.projects.contract_2.stars",
          location: "contract_page.projects.contract_2.location",
          description: "contract_page.projects.contract_2.description",
          sector: "contract_page.projects.contract_2.sector",
        },
        {
          title: "contract_page.projects.contract_3.title",
          stars: "contract_page.projects.contract_3.stars",
          location: "contract_page.projects.contract_3.location",
          sector: "contract_page.projects.contract_3.sector",
        },
        {
          title: "contract_page.projects.contract_4.title",
          stars: "contract_page.projects.contract_4.stars",
          location: "contract_page.projects.contract_4.location",
          sector: "contract_page.projects.contract_4.sector",
        },
        {
          title: "contract_page.projects.contract_5.title",
          stars: "contract_page.projects.contract_5.stars",
          location: "contract_page.projects.contract_5.location",
          sector: "contract_page.projects.contract_5.sector",
        },
        {
          title: "contract_page.projects.contract_6.title",
          stars: "contract_page.projects.contract_6.stars",
          location: "contract_page.projects.contract_6.location",
          sector: "contract_page.projects.contract_6.sector",
        },
        {
          title: "contract_page.projects.contract_7.title",
          stars: "contract_page.projects.contract_7.stars",
          location: "contract_page.projects.contract_7.location",
          sector: "contract_page.projects.contract_7.sector",
        },
        {
          title: "contract_page.projects.contract_8.title",
          stars: "contract_page.projects.contract_8.stars",
          location: "contract_page.projects.contract_8.location",
          sector: "contract_page.projects.contract_8.sector",
        },
        {
          title: "contract_page.projects.contract_9.title",
          stars: "contract_page.projects.contract_9.stars",
          location: "contract_page.projects.contract_9.location",
          sector: "contract_page.projects.contract_9.sector",
        },
        {
          title: "contract_page.projects.contract_10.title",
          stars: "contract_page.projects.contract_10.stars",
          location: "contract_page.projects.contract_10.location",
          sector: "contract_page.projects.contract_10.sector",
        },
        {
          title: "contract_page.projects.contract_11.title",
          stars: "contract_page.projects.contract_11.stars",
          location: "contract_page.projects.contract_11.location",
          sector: "contract_page.projects.contract_11.sector",
        },
        {
          title: "contract_page.projects.contract_12.title",
          stars: "contract_page.projects.contract_12.stars",
          location: "contract_page.projects.contract_12.location",
          sector: "contract_page.projects.contract_12.sector",
        },
        {
          title: "contract_page.projects.contract_13.title",
          stars: "contract_page.projects.contract_13.stars",
          location: "contract_page.projects.contract_13.location",
          sector: "contract_page.projects.contract_13.sector",
        },
        {
          title: "contract_page.projects.contract_14.title",
          stars: "contract_page.projects.contract_14.stars",
          location: "contract_page.projects.contract_14.location",
          sector: "contract_page.projects.contract_14.sector",
        },
        {
          title: "contract_page.projects.contract_15.title",
          stars: "contract_page.projects.contract_15.stars",
          location: "contract_page.projects.contract_15.location",
          sector: "contract_page.projects.contract_15.sector",
        },
        {
          title: "contract_page.projects.contract_16.title",
          stars: "contract_page.projects.contract_16.stars",
          location: "contract_page.projects.contract_16.location",
          sector: "contract_page.projects.contract_16.sector",
        },
        {
          title: "contract_page.projects.contract_17.title",
          stars: "contract_page.projects.contract_17.stars",
          location: "contract_page.projects.contract_17.location",
          sector: "contract_page.projects.contract_17.sector",
        },
        {
          title: "contract_page.projects.contract_18.title",
          stars: "contract_page.projects.contract_18.stars",
          location: "contract_page.projects.contract_18.location",
          sector: "contract_page.projects.contract_18.sector",
        },
      ],
    };
  },
  mounted() {
    this.backgroundImage = this.images;
  },
  methods: {
    ContentLoaded(e) {
      if (e.target == null) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }
      setTimeout(() => {
        element.style.opacity = 1;
      }, 450);
    },
    sendToContract(ref) {
      this.$router.push("/contract");
      setTimeout(() => {
        this.$root.$emit("ref", ref);
      }, 500);
    },
    prevHotel() {
      document.getElementById("backgroundImgContract").style.opacity = 0;
      document.getElementById("data").style.opacity = 0;
      setTimeout(() => {
        if (this.position >= 1) {
          this.position--;
        } else {
          this.position = this.images.length - 1;
        }
      }, 410);
      setTimeout(() => {
        document.getElementById("data").style.opacity = 1;
      }, 550);
    },
    nextHotel() {
      document.getElementById("backgroundImgContract").style.opacity = 0;
      document.getElementById("data").style.opacity = 0;
      setTimeout(() => {
        if (this.position <= this.images.length - 2) {
          this.position++;
        } else {
          this.position = 0;
        }
      }, 410);
      setTimeout(() => {
        document.getElementById("data").style.opacity = 1;
      }, 550);
    },
  },
};
</script>

<style lang="scss">
.c-contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 700px) {
    margin-bottom: 160px;
  }
  &__title {
    color: black;
    font-weight: bold;
    text-align: center;
  }
  &__underline {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  &__carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 550px;
    width: 100%;
    &__container {
      overflow: hidden;
      position: relative;
      height: 550px;
      width: 100%;
      &__loader {
        width: 100%;
        height: 100%;
        background-image: url("../assets/images/loader.gif");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
      }
      &__background {
        overflow: hidden;
        width: 100%;
        opacity: 0;
        transition: 0.4s opacity ease-in-out;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
        background-size: cover;
        height: 550px;
        cursor: pointer;
        position: absolute;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
        &:hover {
          transform: scale(1.1);
          -webkit-filter: brightness(65%);
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        }
      }
    }

    &__data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      height: 550px;
      width: 38%;
      background: #f3f3f5;
      color: black;
      &__container {
        opacity: 1;
        transition: 0.4s opacity ease-in-out;
        padding: 10px;
        margin-top: -220px;
        &__title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          &__name {
            display: flex;
            flex-direction: row;
            font-weight: bold;
            align-content: flex-start;
            align-content: flex-start;
          }
          &__stars {
            font-weight: bold;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            align-content: flex-end;
            align-content: flex-end;
          }
        }

        &__location {
          font-weight: 14px;
          font-weight: bold;
          margin-bottom: 15px;
        }
        &__sector {
          font-weight: 14px;
          font-weight: bold;
          color: #b38a21;
        }
        &__buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          margin-top: 100px;
          position: absolute;
        }
        &__link {
          button {
            font-weight: bold;
            color: black;
            -webkit-tap-highlight-color: transparent;
            text-decoration: none;
            padding: 20px;
            border-radius: 10px;
            border: 2px solid #e9be35;
            transition: 0.4s ease-in-out color;
            cursor: pointer;
            &:hover {
              color: #e9be35;
            }
          }
          &__space {
            margin-top: 65px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    &__carousel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      height: 550px;
      width: 100%;
      &__background {
        width: 100%;
        opacity: 1;
        transition: 0.4s opacity ease-in-out;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 550px;
      }
      &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        height: 150px;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
        color: black;
        &__container {
          opacity: 1;
          transition: 0.4s opacity ease-in-out;
          padding: 10px;
          margin-top: 50px;
          &__title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            &__name {
              display: flex;
              flex-direction: row;
              font-weight: bold;
              align-content: center;
              align-items: center;
            }
            &__stars {
              margin-right: 10px;
              font-weight: bold;
            }
          }

          &__location {
            font-weight: 14px;
            font-weight: bold;
            margin-bottom: 15px;
          }
          &__sector {
            font-weight: 14px;
            font-weight: bold;
            color: #b38a21;
          }
          &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            margin-top: -100px;
            right: 0;
            margin-right: 100px;
            position: absolute;
            @media screen and (max-width: 400px) {
              margin-top: -90px;
              margin-right: 50px;
              @media screen and (max-width: 350px) {
                margin-top: -70px;
              }
            }
          }
          &__link {
            button {
              font-weight: bold;
              color: black;
              -webkit-tap-highlight-color: transparent;
              text-decoration: none;
              padding: 20px;
              border-radius: 10px;
              border: 2px solid #e9be35;
              transition: 0.4s ease-in-out color;
              cursor: pointer;
              &:hover {
                color: #e9be35;
              }
            }
            &__space {
              margin-top: 65px;
            }
          }
        }
      }
    }
  }
}
.arrow {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-top: 170px;
  display: inline-block;
  padding: 12px;
  @media screen and (max-width: 700px) {
    padding: 8px;
    @media screen and (max-width: 350px) {
      padding: 5px;
    }
  }
  &__left {
    border: solid black;
    border-width: 0 3px 3px 0;
    transition: 0.4s ease-in-out;
    margin-right: 20px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);

    &:hover {
      border: solid #e9be35;
      border-width: 0 3px 3px 0;
    }
  }
  &__right {
    border: solid black;
    border-width: 0 3px 3px 0;
    transition: 0.4s ease-in-out;
    margin-left: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    &:hover {
      border: solid #e9be35;
      border-width: 0 3px 3px 0;
    }
  }
}
</style>
