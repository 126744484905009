<template>
  <div class="apploader">
    <img :src="requireAsset('logo.webp')" alt="" class="apploader__image" />
  </div>
</template>

<script>
export default { name: "AppLoader" };
</script>

<style lang="scss" scoped>
.apploader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &__image {
    height: 150px;
    width: 100%;
    animation: 0.7s pump infinite alternate;
  }
}
@keyframes pump {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
