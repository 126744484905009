import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "../src/router/index";
import store from "../src/store/index";
import i18n from "./i18n";

Vue.mixin({
  methods: {
    requireAsset(path) {
      var asset;
      try {
        asset = require("@/assets/images/" + path);
      } catch (error) {
        console.log("%c" + "@/assets/images/" + path, "color:red");
      }
      return asset;
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
