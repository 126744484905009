import { render, staticRenderFns } from "./CarouselComponent.vue?vue&type=template&id=5bc4dfd4&scoped=true&"
import script from "./CarouselComponent.vue?vue&type=script&lang=js&"
export * from "./CarouselComponent.vue?vue&type=script&lang=js&"
import style0 from "./CarouselComponent.vue?vue&type=style&index=0&id=5bc4dfd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc4dfd4",
  null
  
)

export default component.exports