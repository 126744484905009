<template>
  <lazy-component wrapper-tag="section" class="c-clients">
    <h2
      class="c-clients__title g--font-size-8 g--font-size-8@sm g--font-size-9@md g--font-size-10@lg"
    >
      {{ $t("home_page.clients_component.title") }}
    </h2>
    <div
      class="c-clients__title__underline g--width-5 g--width-6@sm g--width-6@md g--width-7@lg g--borderBottom-1 g--borderBottom-2@sm g--borderBottom-3@md g--borderBottom-4@lg"
    ></div>
    <div class="c-clients__items">
      <div
        class="c-clients__items__container"
        v-for="(client, index) in images"
        :key="index"
      >
        <img
          @load="ContentLoaded($event)"
          :src="requireAsset(client)"
          class="c-clients__items__container__image g--width-8 g--width-10@sm g--width-9@md g--width-10@lg"
        />
      </div>
    </div>
  </lazy-component>
</template>

<script>
import LazyComponent from "v-lazy-component";

export default {
  name: "ClientsComponent",
  components: {
    LazyComponent,
  },
  data() {
    return {
      images: [
        "clients/Battoyor_Holding_Company.webp",
        "clients/Casino_Gran_Via.webp",
        "clients/Construye_Capital.webp",
        "clients/Ferratur.webp",
        "clients/Hilton.webp",
        "clients/Marriot.webp",
        "clients/Melia.webp",
        "clients/H10_Hotels.webp",
        "clients/Princesa_Park.webp",
        "clients/San_Jose_Constructora.webp",
        "clients/DBD.webp",
      ],
    };
  },
  methods: {
    ContentLoaded(e) {
      if (e.target == null) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }
      setTimeout(() => {
        element.style.opacity = 1;
      }, 450);
    },
  },
};
</script>

<style lang="scss">
.c-clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 350px;
  &__title {
    color: black;
    font-weight: bold;

    &__underline {
      margin-top: 10px;
    }
  }
  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;

    &__container {
      margin: 15px;
      background-image: url("../assets/images/loader.gif");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100px;
      &__image {
        opacity: 0;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
      }
    }
  }
}
</style>
